import {ServiceData} from './ServiceData.js';
import '../Services/Services.css';
export default function Services({image,title, description}) {
    return (
        <section className='services' id='services'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='services-heading'>
                        <img src={image} alt={title} />
                        <h3>{title}</h3>
                        <p className='text-center'>{description}</p>
                       
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}