import '../Counter/Counter.css';
export default function Counter({ count, title }) {
    return (

        <section className="counter-section pb-5 pt-5">
            <div className='counter'>
                <div className="cards">
                    <h3>{count}</h3>
                    <p>{title}</p>
                </div>
            </div>

        </section>

    );
}