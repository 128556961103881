// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=text]{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  } 
  button{
    padding: 10px;
    width: 100%;
    background-color: rgb(254,199,47);
    color: black;

  }
  .login{
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .resetpassword{
    display: flex;
    flex-direction: column;
    align-items: center;

  }`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,WAAW;IACX,iCAAiC;IACjC,YAAY;;EAEd;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;EAErB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;EAErB","sourcesContent":["input[type=text]{\r\n    width: 100%;\r\n    padding: 12px;\r\n    border: 1px solid #ccc;\r\n    margin-top: 6px;\r\n    margin-bottom: 16px;\r\n    resize: vertical;\r\n  } \r\n  button{\r\n    padding: 10px;\r\n    width: 100%;\r\n    background-color: rgb(254,199,47);\r\n    color: black;\r\n\r\n  }\r\n  .login{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n\r\n  }\r\n  .resetpassword{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
