// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a{
    text-decoration: none;
}
.copywright-text{
    margin-left: 150px;
}
.footer{
    margin-top: -50px;
}
@media(max-width: 480px){
    .copywright-text{
        margin-left: -2px;
        padding-left: 12px;
    }
    .footer{
        margin-top: -50px;
    }
    .footer-about{
        margin-top: -100px;
    }


}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,iBAAiB;QACjB,kBAAkB;IACtB;IACA;QACI,iBAAiB;IACrB;IACA;QACI,kBAAkB;IACtB;;;AAGJ","sourcesContent":["a{\r\n    text-decoration: none;\r\n}\r\n.copywright-text{\r\n    margin-left: 150px;\r\n}\r\n.footer{\r\n    margin-top: -50px;\r\n}\r\n@media(max-width: 480px){\r\n    .copywright-text{\r\n        margin-left: -2px;\r\n        padding-left: 12px;\r\n    }\r\n    .footer{\r\n        margin-top: -50px;\r\n    }\r\n    .footer-about{\r\n        margin-top: -100px;\r\n    }\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
