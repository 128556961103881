export const CounterData=[
    {
        count: '1L+',
        title:'Total order delivered',
    },
    {
        count: '4000+',
        title:'Unique customers',
    },
    {
        count: '2000+',
        title:'repeated customers',
    },
    {
        count: '30+',
        title:'Small business',
    },
]