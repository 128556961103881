import '../Header/Header.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';


export default function Header() {
  return (
  <BrowserRouter>
    
    <nav className="navbar navbar-expand-lg bg-white fixed-top">
      <div className="container-fluid">
        <div className='navbar-logo-toggle-icon'>
        <a className="navbar-brand fs-2 fw-bold " href="#">Turtu</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        </div>
        <div className="collapse navbar-collapse">
          <div className="navbar-nav ms-auto mb-2 mb-lg-0">
            <div className="nav-item">
              <div className="nav-link active">
                <Link to='#home' smooth className='navigation'>Home</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='#about' smooth className='navigation'>About</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='#services' smooth className='navigation'>Services</Link>
              </div>
            </div>
            <div className="nav-item">
              <div className="nav-link">
                <Link to='#contact' smooth className='navigation'>Contact</Link>
              </div>
            </div>
            {/*<div className="nav-item">
              <div className="nav-link">
                <Link to='#' smooth className='navigation'>Blogs</Link>
              </div>e
            </div>*/}
          </div>
        </div>
      </div>
    </nav>
    {/*<Routes>
    
      <Route path="/Login" element={<Login/>}/>
    </Routes>*/}
  </BrowserRouter>
  );
}