import React, { useState } from 'react';
import '../Testimonial/Testimonial.css';
import img from '../../Images/profile.png';

const testimonials = [
    {
        id: 1,
        name: 'Dania Shaik',
        message: 'Excellent service! The delivery was done very quickly and the delivery agent was very kind and professional too!!'
    },
    {
        id: 2,
        name: 'Madhuri Yardi',
        message: 'They are the best delivery service in belgaum i hav come across for my cakes and other stuff .. Also at very reasonable rates.. Very professional!'
    },
    {
        id: 3,
        name: 'Sphoorti Sanamannavar',
        message: 'Quick response and fast delivery. I  recommend this service if you want to deliver anything you want around the city!! Very polite delivery men.'
    },
    {
        id: 4,
        name: 'Akshay shettennavar',
        message: 'Wonderful service. Even though I ordered from thousands of miles away from India they get my delivery done asap. Thank you you are doing a wonderful job 🎉😊'
    },
    {
        id: 5,
        name: 'Pavitra Hegde',
        message: 'Cake was delivered intact across nearly 10kms despite of bad roads and heavy rainfall.Very professional and polite delivery executive.'
    },
    {
        id: 6,
        name: 'Deepa Halapanavar',
        message: 'Perfect delivery of a large cake . Happy with the service'
    },
];

export default function TestimonialCarousel() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevTestimonial = () => {
        const newIndex = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextTestimonial = () => {
        const newIndex = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <div className="testimonial-carousel">
            <button className="carousel-button prev" onClick={prevTestimonial}><i className="fa-solid fa-angle-left"></i></button>
            <div className="testimonial">
                <div className="testimonial-card">
                    <div className="quote">
                        <i className="fa fa-quote-left"></i>
                    </div>
                    <p>{testimonials[currentIndex].message}</p>
                    <div className="ratings">
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                    </div>
                    <div className="profile">
                        <div className="profile-image">
                            <img src={img} alt="profile" />
                        </div>
                        <div className="profile-desc">
                            <span>{testimonials[currentIndex].name}</span>
                        </div>
                    </div>
                </div>
                
            </div>
            <button className="carousel-button next" onClick={nextTestimonial}><i className="fa-solid fa-angle-right"></i></button>
        </div>
    );
}
