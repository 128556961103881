import pickanddropimg from '../../Images/pickanddrop.png';
import courierservicesimg from '../../Images/courierservice.png';
import foodimg from '../../Images/food.png';
import cakeimg from '../../Images/cake.png';
import giftimg from '../../Images/gift.png';
import medicineimg from '../../Images/medicine.png';

export const ServiceData = [
  {
    image: pickanddropimg,
    title: 'Pick and Drop',
    description:
      'Have you let or forgotten anything at home/office/anywhere? get it picked and dropped at your door step',
  },
  {
    image: courierservicesimg,
    title: 'Courier Services',
    description:
      'Have you let or forgotten anything at home/office/anywhere? get it picked and dropped at your door step',
  },
  {
    image: foodimg,
    title: 'Food Delivery',
    description:
      'Now get mouth-watering, tastiest food from all the restaurants',
  },
  {
    image: giftimg,
    title: 'Gifts And Flower',
    description:
      'Have you let or forgotten anything at home/office/anywhere? get it picked and dropped at your door step',
  },
  {
    image: cakeimg,
    title: 'Cake delivery',
    description:
      'Have you let or forgotten anything at home/office/anywhere? get it picked and dropped at your door step',
  },
  {
    image: medicineimg,
    title: 'Medicine Delivery',
    description:
      'Now its easy for you to get your daily/monthly medicines home delivered',
  },
]