// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/*counter*/
.counter-section{
  width: 25%;
}
.container{
  display:flex;
  flex-direction: row;  
  justify-content: center;
  gap: 10px;
}
  
  /* Style the counter cards */
  .cards {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    background-color: rgb(254,199,47);
    color: black;
    width: 100%;
  }
  @media(max-width: 480px){
    .container{
      display: flex ;
      flex-direction: column !important;
      margin-top: 100px;
    }
    .counter-section{
      width: 90%;
      margin-left: 13px;
    }
    .cards{
      margin-top: -90px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Counter/Counter.css"],"names":[],"mappings":";AACA,UAAU;AACV;EACE,UAAU;AACZ;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;EAEE,4BAA4B;EAC5B;IACE,0CAA0C;IAC1C,aAAa;IACb,kBAAkB;IAClB,iCAAiC;IACjC,YAAY;IACZ,WAAW;EACb;EACA;IACE;MACE,cAAc;MACd,iCAAiC;MACjC,iBAAiB;IACnB;IACA;MACE,UAAU;MACV,iBAAiB;IACnB;IACA;MACE,iBAAiB;IACnB;EACF","sourcesContent":["\r\n/*counter*/\r\n.counter-section{\r\n  width: 25%;\r\n}\r\n.container{\r\n  display:flex;\r\n  flex-direction: row;  \r\n  justify-content: center;\r\n  gap: 10px;\r\n}\r\n  \r\n  /* Style the counter cards */\r\n  .cards {\r\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);\r\n    padding: 20px;\r\n    text-align: center;\r\n    background-color: rgb(254,199,47);\r\n    color: black;\r\n    width: 100%;\r\n  }\r\n  @media(max-width: 480px){\r\n    .container{\r\n      display: flex ;\r\n      flex-direction: column !important;\r\n      margin-top: 100px;\r\n    }\r\n    .counter-section{\r\n      width: 90%;\r\n      margin-left: 13px;\r\n    }\r\n    .cards{\r\n      margin-top: -90px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
