import './Login.css';
export default function Login() {
    return (
        <div id="Login" className='login'>
            <div className="reset-pwd">
                <h2>Login</h2>
            </div>
            <div>
                <form>
                    <div className="input-control">
                        <label for="username">Username</label>
                        <input type="text" name='username' placeholder="Username"
                        />
                    </div>
                    <div className="input-control">
                        <label for="password">Password</label>
                        <input type="text" name='pwd' placeholder="Password"
                        />
                    </div>
                    <div className="input-control">
                        <button className=''>Reset Password</button>
                    </div>
                </form>

            </div>
        </div>
    );
}