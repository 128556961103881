import Header from './components/Header/Header.js';
import Carousel from './components/Carousel/Carousel.js';
import About from './components/About/About.js';
import Counter from './components/Counter/Counter.js';
import Services from './components/Services/Services.js';
import Form from './components/Form/Form.js';
import Testimonial from './components/Testimonial/Testimonial.js';
import './App.css';
import Footer from './components/Footer/Footer.js';
import Login from './components/Login/Login.js';
import { ServiceData } from './components/Services/ServiceData.js';
import { CounterData } from './components/Counter/CounterData.js';
import './components/Testimonial/Testimonial.css';
import TestimonialCarousel from './components/Testimonial/Testimonial.js';



function App() {
  const getBox=()=>{
    return document.querySelector('.CarouselContainer')
  }
  const prev=()=>{
    const box=getBox();
    const width=box.clientWidth;
    box.scrollLeft=box.scrollLeft-width;
  }
  const next=()=>
    {
      const box=getBox();
      const width=box.clientWidth;
      box.scrollLeft=width+box.scrollLeft;
    }
  return (
    <>

      <Header />
      <Carousel />

      <About />
      <div className="counter container">
        <Counter
          count={CounterData[0].count}
          title={CounterData[0].title}
        />
        <Counter
          count={CounterData[1].count}
          title={CounterData[1].title}
        />
        <Counter
          count={CounterData[2].count}
          title={CounterData[2].title}
        />
        <Counter
          count={CounterData[3].count}
          title={CounterData[3].title}
        />

      </div>
      <header className="header text-center mb-2">
          <h2>Services</h2>
        </header>
        <div className='service1'>
      <div className='container'>
      
        <Services
          image={ServiceData[0].image}
          title={ServiceData[0].title}
          description={ServiceData[0].description}
        />
        <Services
          image={ServiceData[1].image}
          title={ServiceData[1].title}
          description={ServiceData[1].description}
        />
        <Services
          image={ServiceData[2].image}
          title={ServiceData[2].title}
          description={ServiceData[2].description}
        />
        </div>
      </div>
      <div className='service2'>
      <div className='container'>
        
        <Services
          image={ServiceData[3].image}
          title={ServiceData[3].title}
          description={ServiceData[3].description}

        />
        <Services
          image={ServiceData[4].image}
          title={ServiceData[4].title}
          description={ServiceData[4].description}

        />
        <Services
          image={ServiceData[5].image}
          title={ServiceData[5].title}
          description={ServiceData[5].description}

        />
      </div>
      </div>
      {/*<div>
        <header className="header text-center my-5">
          <h2>What customer say</h2>
        </header>
        <div className='CarouselContainer'>
        <button className='prev' onClick={prev}>prev</button>
        <ul className='testimonialdata'>
          {TestimonialData.map((conceptItem) => (
            <Testimonial key={conceptItem.name} {...conceptItem} />
          ))}
        </ul>
        <button className='next' onClick={next}>next</button>
        </div>
      </div>*/}
      <div className="App">
      <header className="header text-center my-5">
          <h2>What customer say</h2>
        </header>
            <TestimonialCarousel />
        </div>

      <Form />
      <Footer />
      



    </>

  );
}

export default App;
