// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services{
    display:flex;
    align-items: center;
    margin: 10px;
}

.services-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
    width:400px;
    height: 100%;
    align-items: center;
    padding: 20px;
    border-radius: 12px;   

}
img{
    height:50%;
    width: 50%;
  
}
.services-heading:hover{
    background-color: rgb(254,199,47);
    
}
.services h3{
    color: white;
}
.services p{
    color: white;
}
@media(max-width: 480px){
    
    .services-heading{
        height: 100%;
        width: 350px;
        margin-left: 18px;
        margin-right: 18px;
    }
    .col{
        margin-top: -100px;
    }
    
    
    
}


`, "",{"version":3,"sources":["webpack://./src/components/Services/Services.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,mBAAmB;;AAEvB;AACA;IACI,UAAU;IACV,UAAU;;AAEd;AACA;IACI,iCAAiC;;AAErC;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;;IAEI;QACI,YAAY;QACZ,YAAY;QACZ,iBAAiB;QACjB,kBAAkB;IACtB;IACA;QACI,kBAAkB;IACtB;;;;AAIJ","sourcesContent":[".services{\r\n    display:flex;\r\n    align-items: center;\r\n    margin: 10px;\r\n}\r\n\r\n.services-heading{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    background-color: black;\r\n    width:400px;\r\n    height: 100%;\r\n    align-items: center;\r\n    padding: 20px;\r\n    border-radius: 12px;   \r\n\r\n}\r\nimg{\r\n    height:50%;\r\n    width: 50%;\r\n  \r\n}\r\n.services-heading:hover{\r\n    background-color: rgb(254,199,47);\r\n    \r\n}\r\n.services h3{\r\n    color: white;\r\n}\r\n.services p{\r\n    color: white;\r\n}\r\n@media(max-width: 480px){\r\n    \r\n    .services-heading{\r\n        height: 100%;\r\n        width: 350px;\r\n        margin-left: 18px;\r\n        margin-right: 18px;\r\n    }\r\n    .col{\r\n        margin-top: -100px;\r\n    }\r\n    \r\n    \r\n    \r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
